// assets/css/global.scss
$primary: #00aae1;
$dark-bg : #1c1c1c; // noir
$dark-color : #f1f1f1; //gris clair


@import "import";
@import "dark-mode";
@import "navbar";
@import "function";
@import "form";
@import "scrollbar";
@import "media-queries";
@import "components";
@import "./fonts/inter.css";


/*
===== BODY =====
*/
body{
  font-size: 0.875rem;
  margin: 0;
  font-family: Inter, sans-serif;
  color: rgb(72 78 84);
}


/*
===== GRID =====
*/

.grid-container {
  display: grid;
  grid-template-columns: 260px 1fr 1fr 1fr;
  grid-template-rows: 0.2fr 3fr;
  grid-template-areas:
    "header header header header"
    "sidebar main main main";
  height: 100vh;
}

.container{
  max-width: 90vw;
}



/*.custom-file{
  .custom-file-label::after{
    content: "Choisir un fichier";
  }
  input[type="file"][multiple] + label.custom-file-label::after{
    content: "Choisir un ou des fichier(s)";
  }
}*/

/*.card{
  border: 0;
  border-radius: 5px 5px 0px 0px;
}*/

.twitter-typeahead{
  display:block !important;//override script
  width: 100%;
}
.tt-menu{
  width: 100%;
  background: #fff;
  margin-top: 45px;
  color: #ccc;
}

.typeahead,
.tt-query,
.tt-hint {
  width: 396px;
  height: 30px;
  padding: 8px 12px;
  font-size: 24px;
  line-height: 30px;
  border: 2px solid #ccc;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  outline: none;
}

.typeahead {
  background-color: #fff;
}

.typeahead:focus {
  border: 2px solid #0097cf;
}

.tt-query {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
  color: #999
}

.tt-menu {
  width: 422px;
  margin: 12px 0;
  padding: 8px 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0 5px 10px rgba(0,0,0,.2);
  -moz-box-shadow: 0 5px 10px rgba(0,0,0,.2);
  box-shadow: 0 5px 10px rgba(0,0,0,.2);
}

.tt-suggestion {
  padding: 3px 20px;
  font-size: 18px;
  line-height: 24px;
}

.tt-suggestion:hover {
  cursor: pointer;
  color: #fff;
  background-color: #0097cf;
}

.tt-suggestion.tt-cursor {
  color: #fff;
  background-color: #0097cf;

}

.tt-suggestion p {
  margin: 0;
}

.gist {
  font-size: 14px;
}

#map {
  min-height: 400px;
}
.vat-checker-container{
  cursor: pointer;
}

.card-phone{
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: rgba(50,50,93,0.25) 0px 6px 12px -2px, rgba(0,0,0,0.3) 0px 3px 7px -3px;
}

.tr_data_link_redirect{
  cursor: pointer;
}

.inner-text-design{
  gap: 0.5rem;
  margin-left: auto;
}
.fusion-inventory-size-color-icons{
  width: 20px;
  fill: white;
}

/* ---------- MAIN SECTION ---------- */

.home-section{
  position: relative;
  grid-area: main;
  overflow-y: auto;
  padding: 20px 20px;
  left: 0px;
  width: calc(100% - 0px);
  transition: all 0.5s ease;
  background-color: #EFEFF5;
}

.shadow-light{
  box-shadow: 0 6px 20px -4px rgba(0,0,0,0.15);
}

.sidebar.close ~ .home-section{
  left: -185px;
  width: calc(100% + 185px);
}
.reduct{
  width: calc(100% - 0px);
  left: 0px;
  transition: all 0.5s ease;
}

/* ---------- WAPLI COLOR ---------- */
.wapli-color{
  color: $primary!important;
}
.wapli-bg{
  background-color: $primary;
}

/*----------- PIE PROJECT --------------*/

#pie-project{
  @property --p{
    syntax: '<number>';
    inherits: true;
    initial-value: 1;
  }
  .pie {
    --p:20;      /* the percentage */
    --b:22px;    /* the thickness */
    --c:darkred; /* the color */
    --w:150px;   /* the size*/

    width:var(--w);
    aspect-ratio:1/1;
    position:relative;
    display:inline-grid;
    margin:5px;
    place-content:center;
    font-size:25px;
    font-weight:bold;
    font-family: sans-serif;
    &:before{
      inset:0;
      background:
              radial-gradient(farthest-side,var(--c) 98%,#0000) top/var(--b) var(--b) no-repeat,
              conic-gradient(var(--c) calc(var(--p)*1%),#0000 0);
      -webkit-mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
      mask:radial-gradient(farthest-side,#0000 calc(99% - var(--b)),#000 calc(100% - var(--b)));
    }
    &:after{
      inset:calc(50% - var(--b)/2);
      background:var(--c);
      transform:rotate(calc(var(--p)*3.6deg - 90deg)) translate(calc(var(--w)/2 - 50%));
    }
  }
  .pie:before,
  .pie:after {
    content:"";
    position:absolute;
    border-radius:50%;
  }
  .animate {
    animation:p 1s .5s both;
  }
  .no-round{
    &:after{
      content:none;
    }
    &:before {
      background-size: 0 0, auto;
    }
  }
  @keyframes p{
    from{--p:0;}
  }
}

/* PASSWORD */

.password-spoiler{
  background-color: #333333;
  color: transparent;
  border-radius: 0.2em;
  cursor: pointer;
  transition: 250ms;
}

/* ---------- ORDER TRACKING ---------- */
.prix-achat-order-tracking{
  span{
    display: none;
  }
  &:hover{
    span{
      display: block;
    }
  }
}

#card-order-tracking{
  .bg-devis{
    background-color: #f5f5f5;
  }

}
.progress{
  background-color: #9f9f9f;
  height: 25px;
  cursor: pointer;
  .progress-bar{
    overflow: initial;
    small{
      font-size: .775rem;
      text-shadow: 0 0 5px rgba(0,0,0,0.5);
    }
  }
}

.text-info-general{
  font-size: .8rem;
}


.card-header{
  h4{
    font-size: 1rem;
    line-height: 2;
  }
  span.material-icons-outlined{
    font-size: 30px;
    vertical-align: bottom;
  }
}

.description-content{
  table{
    width: 100%;
  }
}

.glpi-async{
  &.loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 3px solid;
    border-color: $primary $primary transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  &.loader::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid;
    border-color: transparent #FF3D00 #FF3D00;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    animation: rotationBack 0.5s linear infinite;
    transform-origin: center center;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes rotationBack {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
}

.vertical-align-middle, .material-symbols-outlined{
  vertical-align: middle;
}

.btn{
  @extend .btn-sm;
}

.table{
  color: #777;
}

#selectAll{
  margin-left: 17.5%;
}

.bouton-police {
  background-color: #3f7fff;
  border-radius: 0.2rem;
  animation: sirene .7s linear infinite alternate;
  color: white;
  border: 0;
}

@keyframes sirene {
  from {	background: linear-gradient(to right, #ff0000 0%,#ff0000 100%);}
  to {	background: linear-gradient(to right, #3f7fff 0%,#3f7fff 100%);}
}

#equipments-on-site{
  .offcanvas-body{
    table{
      width: auto;
    }
  }
}

.popover{
  max-width: fit-content;
}

.svg-image{
  height: 24px;
  width: 24px;
  object-fit: contain;
}

.logo-fournisseur{
  filter: drop-shadow(2px 4px 6px black);
}

.logo-company{
  mix-blend-mode: multiply;
}



