/* ---------- MEDIA QUERIES ---------- */


/* Medium <= 992px */
@media screen and (max-width: 992px) {
  .header{
    left: 0;
    width: calc(100% - 0px);
  }
  .sidebar.close ~ .home-section{
    left: -260px!important;
    width: calc(100% + 260px)!important;
    z-index: 100;
  }
  .profile-div{
    right: 4%;
  }
  .grid-container {
    display: block;
  }

  #sidebar.sidebar.close {
    display: none;
  }
  #sidebar.sidebar {
    display: block;
    width: 100%;
    .nav-links{
      li{
        .closebtnMobile{
          display: block;
        }
      }
    }
  }

  .menu-icon {
    display: inline;
  }

  .sidebar-title > span {
    display: inline;
  }
}
@media (max-width: 400px) {
  .sidebar{
    width: 78px;
  }
  .sidebar.close{
    width: 0;
    .nav-links{
      li{
        .sub-menu{
          display: none;
        }
      }
    }
  }
  .sidebar.close ~ .home-section{
    width: 100%;
    left: 0;
  }
}

/* Extra Small <= 576px */
@media screen and (max-width: 576px) {
  .header-left {
    display: none;
  }
}