.dark-bg{
  background-color: #222F3E;
}

body.dark{
  background-color: $dark-bg;
  color: $dark-color;
  .popover{
    .popover-header{
      background-color: #004C65;
      color: #fff!important;
    }
    .popover-body{
      background-color: RGBA(33, 37, 41, 1);
      color: #fff;
    }
  }

  .card{
    border: 0;
  }
  button{
    span{
      color: white;
    }
    span.password-spoiler{
      color: transparent;
      &:hover{
        color: white;
      }
    }
  }
  .select2-container--default .select2-selection--single .select2-selection__rendered{
    color: white;
  }
  .accordion-button::after{
    filter: brightness(1000);
  }
  .accordion-button:not(.collapsed){
    background-color: transparent;
  }
  .accordion-button.collapsed, .accordion-item, .list-group-item{
    @extend .dark-bg;
    @extend .text-white;
  }
  .accordion-collapse{
    @extend .bg-dark;
  }
  .h1-blue, .btn-primary{
    //background-color: #004C65;
    border: 0;
  }

  li.breadcrumb-item.active{
    color: $dark-color;
  }
  .toast{
    color: $dark-bg;
  }
  .pagination{
    background-color: #242726;
  }
  .header{
    background-color: #26324A;
  }
  .home-section{
    background-color: #2E3233;
  }
  .card{
    background-color: #242726;
    .card-body{
      .list-group{
        .list-group-item{
          color: $dark-color;
          background-color: #3a3a3a!important;
          border: 1px solid #3C4143;
        }
      }
    }
    .card-header{
      background-color: #004C65;
      border-color: #aab8c5;
    }
  }

  .table-hover>tbody>tr:hover>*{
    color: $dark-color;
  }
  table{
    color: $dark-color;
    tr{
      &:hover{
        color: $dark-color;
      }
    }
  }
  .table-striped tbody tr:nth-of-type(odd){
    background-color: #3C4143;
    td,th{
      color: $dark-color;
    }
  }
  .modal-content, .form-control, .dropdown-menu{
    background-color: $dark-bg;
    color: $dark-color;
    a{
      color: $dark-color;
      &:hover{
        background-color: lighten($dark-bg, 10%);

      }
    }
  }
  a.btn-default, button.btn-default{
    color: $dark-color;
  }
  input, select{
    border: none;
  }
  .select2{
    background-color: #1c1c1c;
    color: #ffffff;
    input{
      color: #ffffff;
    }
  }
  .select2-selection__choice{
    background-color: #222F3E!important;
    border: 0px!important;
  }
  .select2-container--default{
    .select2-selection{
      background-color: #1c1c1c;
      border: 1px solid #1c1c1c;
      color: #ffffff;
    }
    .select2-results__option{
      background-color: #2E3233;
      color: #ffffff;
      &[aria-selected=true]{
        background-color: #21232d;
        color: #ffffff;
      }
    }
  }

  .page-link{
    background-color: #21232d;
    border-color: #2E3233;
    color: white;
  }
  .form-select{
    background-color: #1c1c1c;
    color: #f1f1f1;
  }
  .fas{
    color: white;
  }
  .svg-image{
    filter: invert(100%) brightness(1000%);
  }
  .password-spoiler{
    background-color: #e3e3e3;
    &:hover{
      background-color: transparent;
    }
  }

  .offcanvas.offcanva-dark-mode{
    color: #fff;
    background-color: RGBA(33,37,41,1);
    button{
      color: #fff;
    }
  }
  .logo-company{
    background-color: white;
    mix-blend-mode: normal;
  }
}