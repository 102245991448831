ul.collectionHolder{
  margin: 0;
  padding: 0;
  list-style: none;
  > li{
    @extend .card, .p-3, .mb-3;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    > div{
      flex-grow: 1;
    }
    .removeCollectionBtn{
      margin: .5em 0 .5em 1em;
    }
    .form-control{
      @extend .form-control-sm;
    }
  }
}

.form-group{
  label.required{
    &:after{
      content: '*';
      color: #FF0000;
    }
  }
}

label.custom-file-label{
  display: none;
}

.form-filter-holder{
  form{
    .form-control{
      @extend .form-control-sm;
    }

  }
}

.checkbox-line{
  .form-check{
    @extend .form-check-inline;
  }
}

.multi-col{
  column-count: 3;
}