/* ---------- HEADER ---------- */

.header {
  grid-area: header;
  height: 70px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px 0 30px;
  box-shadow: 0 6px 20px -4px rgba(0,0,0,0.15);
  position: fixed;
  left: 0px;
  top:0;
  width: calc(100% + 0px);
  transition: all 0.5s ease;
  z-index: 1000;
}

.menu-icon {
  display: none;
}

.full_card{
  display: flex;
  width: calc(100%/3.5);
  height: 70px;
  box-shadow: 0px 5px 15px rgba(0,0,0,0.25);
  overflow: hidden;
  .card-content{
    flex: 0 1 100%;
    align-self: center;
    height: 100%;
    padding: 0.5em;
    div{
      margin-bottom: 5px;
    }
  }
  /*  .card-toolbar{
      flex: 0 0 auto;
      width: 0;
      transition: width 300ms;
      background-color: #21232d;
      a{
        color: white;
      }
    }
    &:is(:hover, :focus-within){
      .card-toolbar{
        width: 50%;
        a:not(:last-child){
          border-inline-end: solid 2px #888888;
        }
      }
    }*/
}

/*  PROFILE BUTTON  */

.profile{
  display: inline-block;
  span{
    cursor: pointer;
  }
  p{
    color: #6c7293;
    font-weight: 500;
    margin-left: 35px;
    margin-top: 10px;
    font-size: 12.5px;
    background-color: white;
    padding: 10px;
    border-radius: 25px;
    padding-left: 35px;
  }
}

.profile-div{
  background-color: white;
  width: 190px;
  border: 1px solid #ddd;
  padding: 10px 0px;
  right: 1rem;
  border-radius: 5px;
  position: absolute;
  z-index: 9;
  display: none;
  a{
    p{
      margin: 0px;
      padding: 10px 9px;
    }
    p:hover{
      margin: 0px;
      background-color: $primary;
      border-radius: 0px;
      color: white;
    }
  }
  a:hover{
    text-decoration: none;
  }
}
.profile-div:after{
  content: '';
  position: absolute;
  height: 10px;
  width: 10px;
  background-color: white;
  top: -6px;
  border-left: 1px solid #ddd;
  border-top: 1px solid #ddd;
  transform: rotate(45deg);
  right: 10%;
}
.menu_image{
  clip-path:ellipse(50% 50%);
  height: 24px;
  width: 24px;
  margin-bottom: 5px
}

.notification-div{
  background-color: white;
  border: 1px solid #ddd;
  padding: 10px 0px;
  right: 2.5rem;
  border-radius: 5px;
  top: 3.2rem;
  position: absolute;
  z-index: 9;
  display: none;
  max-height: 90vh;
  overflow: auto;
  p{
    margin: 0px;
    padding: 10px 20px;
    color: #6c7293;
    span{
      font-size: 11px;
      display: block;
    }
  }
  p:hover{
    margin: 0px;
    padding: 10px 20px;
    background-color: $primary;
    border-radius: 0px;
    color: white;
  }
}
.notification-div:after{
  content: '';
  position: absolute;
  height: 10px;
  width: 10px;
  background-color: white;
  top: -6px;
  border-left: 1px solid #ddd;
  border-top: 1px solid #ddd;
  transform: rotate(45deg);
  right: 10%;
}
.noti-icon{
  position: relative;
  cursor: pointer;
}
.noti-head{
  display: block;
  margin: 0px;
  color: #6c7293;
  span{
    position: absolute;
    right: 8%;
    top: 10%;
    padding: 1px 5px;
    color: white;
    border-radius: 20px;
  }
}
.noti-head:hover{
  background-color: white!important;
  color: #6c7293!important;
}
.hr{
  margin: 0px;
  border: none;
  background-color: #ddd;
  height: 1px;
}

/*  BUTTON SWITCH THEME  */

.checkboxTheme{
  opacity: 0;
  position: absolute;
}
.checkboxTheme:checked + .label .ball{
  transform: translateX(24px);
}
.label{
  width: 50px;
  height: 26px;
  background-color: #111;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border-radius: 50px;
  position: relative;
}
.moon{
  color: #f1c40f;
  font-size: 18px;
}
.sun{
  color: #f39c12;
  font-size: 18px;
}
.ball{
  background-color: #fff;
  position: absolute;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  top: 2px;
  left: 2px;
  transition: transform 0.2s ease;
}


/* ---------- SIDEBAR ---------- */

#sidebar {
  grid-area: sidebar;
  overflow: auto;
  height: 100%;
  background-color: #21232d;
  color: #9799ab;
  z-index: 100;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}
.sidebar{
  position: fixed;
  top: 70px;
  left: 0;
  height: calc(100% - 10%);
  width: 260px;
  transition: all 0.5s ease;
  .logo-details{
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    i, span{
      font-size: 30px;
      color: #fff;
      height: 50px;
      min-width: 78px;
      text-align: center;
      line-height: 50px;
    }
  }
  .nav-links{
    padding: 30px 0 150px 0;
    li:hover{
      background: #1d1b31;
      .sub-menu.blank{
        top: 50%;
        transform: translateY(-50%);
      }
    }
    li{
      position: relative;
      list-style: none;
      transition: all 0.4s ease;
      .closebtnMobile{
        display: none;
      }
      .iocn-link{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      i, span{
        height: 50px;
        min-width: 78px;
        text-align: center;
        line-height: 50px;
        color: #fff;
        font-size: 25px;
        cursor: pointer;
        transition: all 0.3s ease;
      }
      a{
        display: flex;
        align-items: center;
        text-decoration: none;
        justify-content: space-between;
        .link_name{
          font-size: 16px;
          font-weight: 400;
          color: #fff;
          transition: all 0.4s ease;
        }
      }
      .sub-menu{
        padding: 6px 6px 14px 55px;
        margin-top: -10px;
        background: #1d1b31;
        display: none;
        a{
          color: #fff;
          font-size: 15px;
          padding: 5px 0;
          white-space: nowrap;
          opacity: 0.6;
          font-weight: 400;
          transition: all 0.3s ease;
        }
        a:hover{
          opacity: 1;
        }
        .link_name{
          display: none;
        }
      }
    }
    li.showMenu{
      i.arrow, span.arrow{
        transform: rotate(-180deg);
      }
      .sub-menu{
        display: block;
      }
    }
  }
  .nav-links::-webkit-scrollbar{
    display: none;
  }
}


.sidebar.close{
  width: 78px;
  opacity: 1;
  overflow: unset!important;
  .nav-links{
    li{
      a{
        .link_name{
          opacity: 0;
          pointer-events: none;
        }
      }
      .iocn-link{
        display: block
      }
      i.arrow, span.arrow{
        display: none;
      }
      .sub-menu{
        position: absolute;
        left: 100%;
        top: -10px;
        margin-top: 0;
        padding: 10px 20px;
        border-radius: 0 6px 6px 0;
        opacity: 0;
        display: block;
        pointer-events: none;
        transition: 0s;
        .link_name{
          font-size: 16px;
          opacity: 1;
          display: block;
        }
      }
      .sub-menu.blank{
        opacity: 1;
        pointer-events: auto;
        padding: 3px 20px 6px 16px;
      }
    }
    li:hover{
      .sub-menu{
        top: 0;
        opacity: 1;
        pointer-events: auto;
        transition: all 0.4s ease;
      }
    }
  }
}
.sidebar.close:hover{
  opacity: 1!important;
}