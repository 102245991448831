/*
===== TABLE =====
*/
thead{
  text-transform: uppercase;
}

/*
===== BUTTON =====
*/
.btn-xs{
  padding: 0.125rem 0.25rem;
  font-size: .775rem;
  border-radius: 0.2rem;
}
.btn-primary{
  @extend .text-white;
}

/*
===== H1 Title =====
*/
.h1-blue{
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-size: 1.5rem;
  line-height: 75px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  a{
    @extend .btn-sm;
  }
}
.button-h1{
  gap: 0.5rem;
  margin-left: auto;
}

/*
===== ACCORDION =====
*/

.accordion{
  .accordion-item{
    img{
      @extend .img-fluid;
    }
  }
}

.accordion-body{
  table{
    width: auto;
  }
}

/*
===== MENU RAPIDE =====
*/
.menu-rapide{
  position: fixed;
  bottom: 0;
  right: 0;
  width: 65px;
  height: 65px;
  margin: 0 2rem 2rem 0;
  display: flex;
  //display: none;
  justify-content: center;
  align-items: center;
  z-index: 100;
  li{
    position: absolute;
    list-style: none;
    left: -85px;
    transform-origin: 100px;
    transition: 0.5s;
    transition-delay: calc(0.1s * var(--i));
    transform: rotate(0deg) translateX(100px);
    a{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      background: #21232d;
      border-radius: 50%;
      transform: rotate(calc(90deg/-2.5*var(--i)));
      box-shadow: 0 3px 4px rgba(0,0,0,0.15);
      color: #fff;
      transition: 0.5s;
    }
    a:hover{
      background-color: $primary;
    }
  }
}
.toggle{
  position: absolute;
  width: 60px;
  height: 60px;
  background: $primary;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 4px rgba(0,0,0,0.15);
  font-size: 2em;
  transition: 0.5s;
  i{
    color: white;
  }
}

.menu-rapide.active{
  .toggle{
    transform: rotate(315deg);
  }
  li{
    transform: rotate(calc(90deg/2.5*var(--i)));
  }
}

/*
===== TOAST =====
*/
#toasts{
  max-height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 900;
  //border: 1px solid green;
  align-items: start;
  #toggle-toasts{
    position: absolute;
    top: .25em;
    left: - 2.5em;
    z-index: 902;
    @extend .text-muted;

    & + div.show{
      & >  #toasts-bell{
        display: none;
      }
      & >  #toasts-count{
        display: none;
      }
      & >  #toasts-arrow{
        display: block;
      }
    }

  }

  #toast-container{
    //border: 1px solid red;
    max-height: 100%;
    overflow: auto;
    .toast{
      margin: .5em auto;
    }
  }
}

/*
===== LINK / LIEN =====
*/
a{
  text-decoration: none;
}

.popover{
  .popover-header{
    @extend .text-dark;
  }
}

/*
===== CANVAJS =====
 */

.front-page-chart{
  .canvasjs-chart-container{
    &>canvas:first-of-type{
      position: relative !important;
    }
    &>canvas:nth-child(2) {
      top: 0;
      left: 0;
    }
  }
  .canvasjs-chart-tooltip{
    bottom: 0!important;
  }
  .canvasjs-chart-credit{
    display: none;
  }
}