@for $i from 1 through 100 {
  .width-#{$i}-percent {
    width: $i * 1%!important;
  }
  .max-width-#{$i}-percent {
    max-width: $i * 1%!important;
  }
}

@for $i from 1 through 100 {
  .height-#{$i}-vh {
    height: $i * 1vh!important;
  }
  .max-height-#{$i}-vh {
    max-height: $i * 1vh!important;
  }
  .max-height-#{$i}-percent {
    max-height: $i * 1%!important;
  }

}
@for $i from 1 through 1000 {
  .max-height-#{$i}-px {
    max-height: $i * 1px!important;
  }
}

@for $i from 1 through 1000 {
  .font-size-#{$i}-em {
    font-size: $i/100 * 1em!important;
  }
}

